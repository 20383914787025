import './App.css';
const dayjs = require('dayjs')

function App() {
  var weekOfYear = require('dayjs/plugin/weekOfYear')
  dayjs.extend(weekOfYear)

  var head = 'Küchenplan KW ';
  var weekNumber = dayjs().week();
  weekNumber -= 1;
  var week = head;

  var presetorder = ['Dominik', 'Noah', 'Matthias', 'Stefan', 'Moo', 'Natalie', 'Dominik', 'Max', 'Alex', 'David'];
  var presetorder1 = ['Dominik', 'Max', 'Natalie', 'Noah', 'Matthias', 'Noah', 'Stefan', 'Alex', 'Moo', 'David'];
  var presetorder2 = ['Matthias', 'Natalie', 'Alex', 'Max', 'Noah', 'Matthias', 'Moo', 'Stefan', 'Dominik', 'David'];
  var presetorder3 = ['Noah', 'Max', 'Moo', 'Matthias', 'Natalie', 'Noah', 'Natalie', 'Max', 'Dominik', 'David'];

  var presets = [presetorder, presetorder1, presetorder2, presetorder3]

  var result = presets[Math.floor(Math.random() * presets.length)];

  var morningArray = result.filter((e, i) => i % 2 === 0);
  var afternoonArray = result.filter((e, i) => i % 2 !== 0);

  var free = 'HOLIDAY';

  function setHoliday(day, week) {
    if (weekNumber === week) {
        if (day === 'monday') {
          morningArray[0] = free;
          afternoonArray[0] = free;    
        } else if (day === 'tuesday') {
          morningArray[1] = free;
          afternoonArray[1] = free;
        } else if (day === 'wednesday') {
          morningArray[2] = free;
          afternoonArray[2] = free;
        } else if (day === 'thursday') {
          morningArray[3] = free;
          afternoonArray[3] = free;
        } else if (day === 'friday') {
          morningArray[4] = free;
          afternoonArray[4] = free;
        }
    }
}

setHoliday('monday', 16);
setHoliday('thursday', 21);
setHoliday('monday', 23);
setHoliday('thursday', 24);
setHoliday('wednesday', 43);
setHoliday('tuesday', 44);
setHoliday('thursday', 49);
setHoliday('monday', 52);

  return (
  <div>
    <img src="alpin11-indigo.svg" className="picture" alt="Alpin11 Logo"/>
    <div className="headlineContainer">
    <h1 className="headline">{week}</h1>
    <input type="number" className='inputStyle' placeholder={weekNumber}></input>
    </div>
    <div className="container">
        <table>
            <tr>
                <th className="tab-text left">MONTAG</th>
                <th className="tab-text left">DIENSTAG</th>
                <th className="tab-text left">MITTWOCH</th>
                <th className="tab-text left">DONNERSTAG</th>
                <th className="tab-text left">FREITAG</th>
            </tr>
            <tr>
                <td className="tab-text-2 left">Morgens</td>
                <td className="tab-text-2 left">Morgens</td>
                <td className="tab-text-2 left">Morgens</td>
                <td className="tab-text-2 left">Morgens</td>
                <td className="tab-text-2 left">Morgens</td>
            </tr>
            <tr>
              {morningArray.map((name, index) => (
                <td key={"morning" + index} className="tab-name left"><input className="input"  placeholder={name} /></td>
              ))}
            </tr>
            <tr>
                <td className="signature2 left">
                    <div className="signature">Unterschrift</div>
                </td>
                <td className="signature2 left">
                    <div className="signature">Unterschrift</div>
                </td>
                <td className="signature2 left">
                    <div className="signature">Unterschrift</div>
                </td>
                <td className="signature2 left">
                    <div className="signature">Unterschrift</div>
                </td>
                <td className="signature2 left">
                    <div className="signature">Unterschrift</div>
                </td>
            </tr>
            <tr>
                <td className="tab-text-2 left">Mittag</td>
                <td className="tab-text-2 left">Mittag</td>
                <td className="tab-text-2 left">Mittag</td>
                <td className="tab-text-2 left">Mittag</td>
                <td className="tab-text-2 left">Mittag</td>
            </tr>
            <tr>
              {afternoonArray.map((name, index) => (
                  <td key={"afternoon" + index} className="tab-name left"><input className="input" placeholder={name} /></td>
                ))}
            </tr>
            <tr>
                <td className="signature2 left" id="monday-holiday1">
                    <div className="signature">Unterschrift</div>
                </td>
                <td className="signature2 left">
                    <div className="signature">Unterschrift</div>
                </td>
                <td className="signature2 left">
                    <div className="signature">Unterschrift</div>
                </td>
                <td className="signature2 left">
                    <div className="signature">Unterschrift</div>
                </td>
                <td className="signature2 left">
                    <div className="signature">Unterschrift</div>
                </td>
            </tr>
        </table>
    </div>
    <div className="line">
        <br />
      </div>
     <p className="footer">PS: BEI NICHTEINHALTUNG ERFOLGT KOLLEKTIVES KOPFABREISSEN ;-)</p>
    <div className="alert alert-warning" role="alert">
     Beim drucken beachten:<br /> Hintergrundgrafiken aktivieren<br /> Ausrichtung: Querformat<br /> Papierformat: A4<br /> Ränder: Keine<br /> Skalieren: Standard
    </div>
  </div>
  );
}

export default App;

//          ______
// |\      |       \
// | \     |        |
// |  \    |______ /
// |   \   |       \
// |    \  |        \
// |     \ |         \
// |      \|          \